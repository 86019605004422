<template>
  <b-icon
    class="copy-icon is-inline-block ml-2"
    icon-pack="fa"
    icon="copy"
    :size="size"
    type="is-blue"
    @click.native="copy"
  ></b-icon>
</template>

<script>
export default {
  name: "CopyToClipboard",
  props: {
    text: {
      type: [String, Number],
      required: true,
      default: "",
    },
    size: {
      type: String,
      default: "is-small",
    },
  },
  methods: {
    async copy() {
      let text = this.text;
      try {
        await navigator.clipboard.writeText(text);
      } catch ($e) {
        console.error($e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.copy-icon {
  cursor: pointer;
}
</style>
