<template>
  <div v-if="loaded">
    <template v-if="isSuccess">
      <div class="purchase-success-container">
        <div class="purchase-success-details-container">
          <template v-if="isLoanApproved">
            <p class="has-text-weight-bold is-size-4 mb-5">
              {{ $t("af:application.purchase_result.loan_approved.title") }}
            </p>
            <p class="mb-4">
              {{ $t("af:application.purchase_result.loan_approved.subtitle") }}
            </p>

            <div class="is-flex">
              <p class="has-text-grey">
                {{
                  $t(
                    "af:application.purchase_result.loan_approved.amount.label"
                  )
                }}
              </p>
              <p class="has-text-weight-bold ml-auto">
                {{ purchase.loanAmount | monify }} Ft
              </p>
            </div>

            <div class="is-flex">
              <p class="has-text-grey">
                {{
                  $t(
                    "af:application.purchase_result.loan_approved.extra_amount.label"
                  )
                }}
              </p>
              <p class="has-text-weight-bold ml-auto">
                {{ purchase.extraAmount | monify }} Ft
              </p>
            </div>

            <p class="mt-2 mb-5">
              {{
                $t(
                  "af:application.purchase_result.loan_approved.money_transfer.subtitle"
                )
              }}
            </p>

            <div class="is-flex">
              <p class="has-text-grey">
                {{
                  $t(
                    "af:application.purchase_result.loan_approved.money_transfer.amount"
                  )
                }}
              </p>
              <p class="has-text-weight-bold ml-auto">
                {{ purchase.totalAmount | monify }} Ft
              </p>
            </div>

            <div class="is-flex">
              <p class="has-text-grey">
                {{
                  $t(
                    "af:application.purchase_result.loan_approved.money_transfer.account_number"
                  )
                }}
              </p>
              <p class="has-text-weight-bold ml-auto copy-content">
                {{ purchase.merchantBankAccountNumber }}
                <CopyToClipboard :text="purchase.merchantBankAccountNumber" />
              </p>
            </div>

            <div class="is-flex">
              <p class="has-text-grey">
                {{
                  $t(
                    "af:application.purchase_result.loan_approved.money_transfer.beneficiary"
                  )
                }}
              </p>
              <p class="has-text-weight-bold ml-auto copy-content">
                {{ purchase.merchantFullName }}
                <CopyToClipboard :text="purchase.merchantFullName" />
              </p>
            </div>

            <div class="is-flex">
              <p class="has-text-grey">
                {{
                  $t(
                    "af:application.purchase_result.loan_approved.money_transfer.note"
                  )
                }}
              </p>
              <p class="has-text-weight-bold ml-auto copy-content">
                {{ purchase.moneyTransferNote }}
                <CopyToClipboard :text="purchase.moneyTransferNote" />
              </p>
            </div>

            <b-field class="mt-4">
              <b-button
                type="is-blue"
                native-type="submit"
                expanded
                @click="openTransferModal"
              >
                <span
                  >{{
                    $t(
                      "af:application.purchase_result.loan_approved.money_transfer.button.transferred"
                    )
                  }}
                </span>
              </b-button>
            </b-field>

            <p class="has-text-grey has-text-justified">
              {{
                $t(
                  "af:application.purchase_result.loan_approved.money_transfer.footer"
                )
              }}
            </p>

            <b-modal
              v-model="isMoneyTransferredModalOpen"
              :can-cancel="false"
              :width="600"
            >
              <div class="af-modal-card">
                <div class="modal-card__content">
                  <p class="has-text-weight-bold is-size-4 mb-4">
                    {{
                      $t(
                        "af:application.purchase_result.loan_approved.money_transfer.title"
                      )
                    }}
                  </p>

                  <p class="has-text-grey">
                    {{
                      $t(
                        "af:application.purchase_result.loan_approved.money_transfer.message",
                        {
                          amount: monify(purchase.totalAmount),
                        }
                      )
                    }}
                  </p>

                  <div class="mt-5 is-flex">
                    <b-field grouped class="ml-auto">
                      <b-button
                        class="mr-3 px-5 cancel-button"
                        type="is-text"
                        @click="closeTransferModal"
                        >{{
                          $t(
                            "af:application.purchase_result.loan_approved.button.cancel"
                          )
                        }}
                      </b-button>

                      <b-button
                        @click="submitTransferModal"
                        class="is-blue px-6"
                        >{{
                          $t(
                            "af:application.purchase_result.loan_approved.button.yes"
                          )
                        }}
                      </b-button>
                    </b-field>
                  </div>
                </div>

                <div
                  class="close-btn cursor-pointer"
                  @click="closeTransferModal"
                >
                  <b-icon icon-pack="fas" icon="times" type="is-grey-icon" />
                </div>
              </div>
            </b-modal>
          </template>

          <template v-if="isMoneyTransferred">
            <p class="has-text-weight-bold is-size-4 mb-1">
              {{ $t("af:application.purchase_result.money_transferred.title") }}
            </p>

            <div class="mt-5 is-flex">
              <img
                class="is-block is-64x64"
                :src="require('@/assets/images/payment-pending.svg')"
                alt="payment-pending"
              />
              <span class="ml-5 has-text-grey">
                {{
                  $t("af:application.purchase_result.money_transferred.message")
                }}
              </span>
            </div>

            <div class="status-container mt-5">
              <p class="has-text-grey has-text-weight-bold mb-0">
                {{
                  $t(
                    "af:application.purchase_result.money_transferred.status.label"
                  )
                }}
              </p>
              <p class="has-text-weight-bold is-size-5">
                {{
                  $t(
                    "af:application.purchase_result.money_transferred.status.value"
                  )
                }}
              </p>
            </div>
          </template>

          <template v-if="isPaid">
            <p class="has-text-weight-bold is-size-4 mb-1">
              {{ $t("af:application.purchase_result.paid.title") }}
            </p>
            <p class="mb-5">
              {{ $t("af:application.purchase_result.paid.subtitle") }}
            </p>

            <application-template
              template="APPLICATION_PURCHASE_SUCCESS"
            ></application-template>

            <b-field class="mt-6">
              <b-button
                type="is-success"
                size="is-large"
                :label="$t('af:application.purchase_result.button.finish')"
                :rounded="true"
                :inverted="true"
                icon-left="check"
                @click="finishApplication"
              />
            </b-field>
          </template>
        </div>
        <div class="purchase-success-basket-container">
          <BasketContent :purchase="purchase" />
        </div>
      </div>
    </template>
    <template v-else>
      <p class="has-text-weight-bold is-size-4 mb-1">
        {{ $t("af:application.purchase_result.loan_denied.title") }}
      </p>
      <p class="mb-5">
        {{ $t("af:application.purchase_result.loan_denied.subtitle") }}
      </p>

      <application-template
        template="APPLICATION_PURCHASE_FAILURE"
      ></application-template>

      <b-field class="mt-6">
        <b-button
          type="is-success"
          size="is-large"
          :label="$t('af:application.purchase_result.button.finish')"
          :rounded="true"
          :inverted="true"
          icon-left="check"
          @click="finishApplication"
        />
      </b-field>
    </template>
  </div>
</template>

<script>
import flowResultMixin from "../../mixins/flowResultMixin";
import BasketContent from "../basket/BasketContent";
import { PURCHASE_STATUS } from "../../utils/const";
import CopyToClipboard from "../CopyToClipboard";
import { monify } from "../../utils/util";
import { mapGetters } from "vuex";

export default {
  name: "ApplicationFlowResult",
  mixins: [flowResultMixin],
  components: {
    CopyToClipboard,
    BasketContent,
    ApplicationTemplate: () => import("@/components/ApplicationTemplate.vue"),
  },
  data() {
    return {
      monify,
      loaded: false,
      isMoneyTransferredModalOpen: false,
    };
  },
  async mounted() {
    await this.$store.dispatch("purchase/hasActivePurchase");
    await this.$store.dispatch("purchase/fetchActivePurchase");

    if (this.isMoneyTransferred) {
      this.startPollingPurchase();
    }

    this.loaded = true;
  },
  methods: {
    openTransferModal() {
      this.isMoneyTransferredModalOpen = true;
    },
    closeTransferModal() {
      this.isMoneyTransferredModalOpen = false;
    },
    async submitTransferModal() {
      try {
        await this.$store.dispatch("purchase/transferred");
        await this.$store.dispatch("purchase/fetchActivePurchase");
        this.startPollingPurchase();
        this.closeTransferModal();
      } catch (e) {
        console.error(e);
      }
    },
    startPollingPurchase() {
      this.poll = window.setInterval(() => {
        this.$store.dispatch("purchase/fetchActivePurchase");
      }, 30000);
    },
  },
  computed: {
    ...mapGetters({
      purchase: "purchase/getActivePurchase",
    }),
    isLoanApproved() {
      return this.purchase.status === PURCHASE_STATUS.LOAN_APPROVED;
    },
    isMoneyTransferred() {
      return this.purchase.status === PURCHASE_STATUS.MONEY_TRANSFERRED;
    },
    isPaid() {
      return this.purchase.status === PURCHASE_STATUS.PAID;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.purchase-success-container {
  display: flex;
  justify-content: center;
  margin-top: 3rem;

  .cancel-button {
    text-decoration: none;
  }

  .copy-content {
    white-space: nowrap;
  }

  .status-container {
    background-color: $grey-2;
    border-radius: 8px;
    padding: 2rem;
  }
}

.purchase-success-details-container {
  max-width: 560px;
  min-width: 560px;
  padding: 32px 36px;
}

.purchase-success-basket-container {
  .af-modal-card {
    max-width: 500px;
    top: -4rem;
    right: 0;
    height: auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
    margin: 0 0 0 2rem !important;
  }
}

@media only screen and (max-width: 769px) {
  .purchase-success-container {
    flex-direction: column;
    justify-content: left;
    overflow: hidden;
    margin-top: 0;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .purchase-success-details-container {
    min-width: 0;
    padding: 0;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 2rem;
  }

  .purchase-success-basket-container {
    .af-modal-card {
      top: 0;
      margin-left: 0 !important;
      margin-right: 0 !important;
      max-width: 100%;
      box-shadow: none;
    }
  }
}
</style>
